@import './assets/styles/tokens';

.gaas-manometer-pin {
  cursor: pointer;

  &--head {
    transition: filter 0.2s ease;
    filter: drop-shadow(0px 1px 2px #0000000d);

    &:hover {
      filter: drop-shadow(0px 1px 3px #0000002a);
    }
  }

  &--red {
    fill: $brand--red-400;
  }
  &--green {
    fill: $brand--green-400;
  }
  &--orange {
    fill: $brand--orange-400;
  }
  &--yellow {
    fill: $brand--yellow-400;
  }
  &--grey {
    fill: $neutral--grey-400;
  }

  &--permission {
    cursor: initial;

    .gaas-manometer-pin--head:hover {
      filter: drop-shadow(0px 1px 2px #0000000d);
    }
  }
}
