@import 'src/stories/assets/styles/tokens';

.gaas-barcode-scanner {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  width: 100%;

  &--prompts {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    row-gap: $spacing-xl;
    padding: $spacing-l;
  }

  &--container {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  &--video {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--canvas {
      width: 100%;
      height: 100%;
    }
  }

  &--buttons {
    position: absolute;
    bottom: $spacing-s;
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: $spacing-xs;
    padding: 0 $spacing-l;

    &--button {
      height: $spacing-4xl;
      width: $spacing-4xl;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 0;
      background-color: rgba($contrast--white, 0.6);

      cursor: pointer;
    }
  }
}
