@import './assets/styles/tokens';

.gaas-group-card {
  display: flex;
  flex-flow: column nowrap;
  row-gap: $spacing-l;

  &--header {
    display: flex;
    align-items: center;
    column-gap: $spacing-l;
    height: $spacing-5xl;
    margin-top: -$spacing-l;
    border-bottom: $border;

    &--title {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
      font-size: $display-xs;
      line-height: $line-height-display;
      font-weight: $semibold;
    }
  }

  &--summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-xs;

    &--box {
      height: $spacing-6xl;
      border: $border;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      &--value {
        width: 5rem;
        height: calc($spacing-6xl - 12px);
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        label {
          font-weight: $light;
        }
        & > div {
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
      &--timeframe {
        font-size: $text-xs;
        color: $neutral--grey-400;
      }
    }
  }

  &--list {
    position: relative;
    overflow: hidden;
    max-height: 304px;

    &--items {
      display: flex;
      flex-flow: column nowrap;
      row-gap: $spacing-m;
    }

    &--withScroll {
      overflow-y: scroll;
    }

    &--withFade {
      .gaas-group-card--list--fade {
        background: linear-gradient($contrast--transparent, $contrast--white);
      }
    }

    &--fade {
      position: sticky;
      bottom: 0;
      height: $spacing-2xl;
      width: 100%;
      margin-top: -$spacing-2xl;
      pointer-events: none;
    }
  }
}
