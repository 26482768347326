@font-face {
  font-family: WestfalenNewsSans;
  font-weight: 300;
  src: url('./WestfalenNewsSans-Light.woff') format('woff');
}
@font-face {
  font-family: WestfalenNewsSans;
  font-weight: 400;
  src: url('./WestfalenNewsSans-Regular.woff') format('woff');
}
@font-face {
  font-family: WestfalenNewsSans;
  font-weight: 600;
  src: url('./WestfalenNewsSans-SemiBold.woff') format('woff');
}
@font-face {
  font-family: WestfalenNewsSans;
  font-weight: 700;
  src: url('./WestfalenNewsSans-Bold.woff') format('woff');
}
