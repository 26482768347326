@import './assets/styles/tokens';

.gaas-content-switcher {
  display: grid;
  height: 32px;
  border: 1px solid $contrast--black;

  &--button {
    width: 100%;
    background-color: $contrast--white;
    border: 0;
    border-right: 1px solid $neutral--grey-100;
    white-space: nowrap;

    &:last-of-type {
      border-right: 0;
    }

    &--selected {
      background-color: $contrast--black;
      color: $contrast--white;
    }
  }
}
