@import 'src/stories/assets/styles/tokens';

.gaas-bottle-details {
  &--title {
    font-size: $display-m;
    line-height: $line-height-display;
    font-weight: $light;
    margin: $spacing-xl 0;
  }

  &--values {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-l;
  }

  &--level {
    display: flex;
    align-items: center;
    column-gap: $spacing-xs;
  }

  &--actions {
    display: flex;
    flex-flow: column nowrap;

    &--store {
      display: flex;
      flex-flow: row nowrap;
      column-gap: $spacing-m;
      margin-bottom: $spacing-m;
    }
  }
}
