@import './assets/styles/tokens';

.gaas-chip {
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: $spacing-2xs;
  color: $neutral--grey-600;
  background-color: $neutral--grey-100;
  border-radius: 40px;
  white-space: nowrap;

  &--s {
    font-size: $text-xs;
    height: 20px;
    padding: 0 8px;

    svg {
      height: 16px !important;
      width: 16px !important;
    }
  }

  &--m {
    font-size: $text-s;
    height: 24px;
    padding: 0 8px;

    svg {
      height: 18px !important;
      width: 18px !important;
    }
  }

  &--l {
    font-size: $text-m;
    height: 32px;
    padding: 0 10px;

    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }

  &--clickable {
    cursor: pointer;
    &:hover {
      background-color: $neutral--grey-200;
    }
    &:active {
      background-color: $neutral--grey-300;
    }
    &:focus {
      outline: 1px solid $brand--blue-400;
    }
  }
}
