@import './assets/styles/tokens';

.gaas-material-warning {
  &--red {
    path {
      fill: $brand--red-400;
    }
  }
  &--green {
    path {
      fill: $brand--green-400;
    }
  }
  &--orange {
    path {
      fill: $brand--orange-400;
    }
  }
  &--yellow {
    path {
      fill: $brand--yellow-400;
    }
  }
  &--grey {
    path {
      fill: $neutral--grey-400;
    }
  }
}
