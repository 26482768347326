@import './assets/styles/tokens';

.gaas-plansModal {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;

  &--icon {
    position: absolute;
    top: -128px;
    height: $spacing-5xl;
    width: $spacing-5xl;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: $brand--lightblue-400;

    svg {
      height: $spacing-2xl;
      width: $spacing-2xl;
      * {
        fill: $contrast--white;
      }
    }
  }

  &--text {
    margin: 0;
    white-space: pre-line;
  }

  &--permission {
    display: flex;
    column-gap: $spacing-m;
    margin: $spacing-xl 0;

    &--current {
      position: relative;
      svg {
        position: absolute;
        z-index: -1;
        height: 32px;
        top: 0;
        right: -56px;
      }

      &--chip {
        position: relative;
        z-index: 2;
        background-color: $neutral--grey-400 !important;
        outline: 4px solid $contrast--white !important;
        margin-right: 62px;
      }
    }

    &--chip {
      height: 28px;
      width: fit-content;
      display: flex;
      align-items: center;
      column-gap: $spacing-2xs;
      margin-top: $spacing-xs;
      padding: 2px 10px;
      border-radius: 40px;
      white-space: nowrap;
      background-color: $brand--lightblue-400;
      color: $contrast--white;
    }
  }
}
