@import './assets/styles/tokens';

.gaas-pin-color-selector {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: 1px solid $neutral--grey-300;
  background-color: $contrast--transparent;
  padding: 12px;
  transition: padding 0.2s ease;

  &--circle {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--red {
      background-color: $brand--red-400;
    }
    &--green {
      background-color: $brand--green-400;
    }
    &--orange {
      background-color: $brand--orange-400;
    }
    &--yellow {
      background-color: $brand--yellow-400;
    }
    &--grey {
      background-color: $neutral--grey-400;
    }

    &--icon * {
      fill: $contrast--white;
    }
  }

  &--selected {
    padding: 4px;
  }

  &:hover {
    padding: 4px;
    border-color: $neutral--grey-400;
    cursor: pointer;
  }

  &:active {
    padding: 4px;
    border-color: $neutral--grey-400;

    .gaas-pin-color-selector--circle {
      &--red {
        background-color: $brand--red-500;
      }
      &--green {
        background-color: $brand--green-500;
      }
      &--orange {
        background-color: $brand--orange-500;
      }
      &--yellow {
        background-color: $brand--yellow-500;
      }
      &--grey {
        background-color: $neutral--grey-500;
      }
    }
  }

  &:focus {
    background-color: $overlay--white-5;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
