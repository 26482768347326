@import './assets/styles/tokens';

.gaas-notification {
  display: flex;
  column-gap: $spacing-m;

  &:hover {
    background-color: $brand--blue-100;
    cursor: pointer;
  }

  &--dot {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    margin: 6px;
    flex-shrink: 0;
    background-color: $neutral--grey-400;

    &--unread {
      background-color: $brand--blue-400;
    }
  }

  &--date {
    color: $neutral--grey-400;
  }

  &--text {
    font-size: $text-l;
    line-height: $line-height;

    &--unread {
      font-weight: $semibold;
    }
  }
}
