@import 'src/stories/assets/styles/tokens';

.gaas-pairing-page {
  &--container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: $spacing-m;
    padding: $spacing-xl 0 $spacing-m 0;
  }
}
