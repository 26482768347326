@import './assets/styles/tokens';

.gaas-manometer {
  position: relative;
  padding-bottom: calc(100% - 2rem);
  overflow: hidden;
  display: flex;
  justify-content: center;

  svg {
    max-width: 380px;
  }

  &-scale {
    position: absolute;
    z-index: 1;
  }
  &-hand {
    position: absolute;
    z-index: 2;
  }
  &-pin {
    position: absolute;
    z-index: 3;
  }
}
