@import './assets/styles/tokens';

.gaas-bottle-list {
  &--header {
    display: grid;
    grid-template-columns: 8rem 1fr;
    margin-bottom: $spacing-s;
  }

  &--wrap {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-xs;
  }

  &--item {
    display: grid;
    grid-template-columns: 8rem 1fr;
    align-items: center;

    &--values {
      display: flex;
      flex-flow: row wrap;
      column-gap: $spacing-2xs;
      row-gap: $spacing-2xs;
    }
    &--barcode {
      text-decoration: none;
      color: $brand--blue-400;
      &:hover {
        color: $brand--blue-500;
      }
    }
  }
}
