@import './assets/styles/tokens';

.gaas-modal {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  opacity: 0;
  background-color: $overlay--black-50;
  padding: $spacing-s;
  align-items: center;
  justify-content: center;

  &--visible {
    opacity: 1;
    visibility: visible;
    animation: $appear-animation;
  }

  &--transitioning {
    animation: $disappear-animation;
  }

  &--container {
    background-color: $contrast--white;
    padding: $spacing-xl;
    box-shadow: $shadow-xl;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;
    row-gap: $spacing-xl;
    width: 100%;
    max-width: 910px;
    max-height: 100%;

    &--fullwidth {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: none !important;
    }

    &--mobile {
      @media only screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: none !important;
      }
    }

    &--tablet {
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: none !important;
      }
    }
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--title {
      font-size: $display-xs;
      line-height: $line-height-display;
      font-weight: $semibold;
      margin: 0;
    }

    &--close {
      background-color: $contrast--transparent;
      border: 0;
      padding: 0;
      height: 24px;
      cursor: pointer;
    }
  }

  &--content {
    overflow-x: clip;
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-l;
    // height 100% was added to get the barcode scanner to
    // extend to the max heigth of the modal
    // On first inspection, this didn't seem to have any side effects
    // ~Sebastian
    height: 100%;
  }

  &--buttons {
    display: flex;
    justify-content: flex-end;

    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-m;
    }
  }
}
