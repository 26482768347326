@import './assets/styles/tokens';

.gaas-material-timeline-item {
  display: grid;
  grid-template-columns: 1fr $spacing-xs 1fr;
  grid-template-rows: 1fr $spacing-s;
  grid-template-areas:
    'aleft adot aright'
    '. aconnector .';
  column-gap: $spacing-s;

  &--end {
    grid-template-rows: 1fr;
    grid-template-areas: 'aleft adot aright';
  }

  &--content {
    &--left {
      grid-area: aleft;
      text-align: right;
    }
    &--right {
      grid-area: aright;
    }
  }

  &--dot {
    grid-area: adot;
    height: $spacing-xs;
    width: $spacing-xs;
    border-radius: 100%;
    margin-top: $spacing-xs;
    border: 1px solid $contrast--black;

    &--red {
      background-color: $brand--red-400;
      border: 0;
    }
    &--green {
      background-color: $brand--green-400;
      border: 0;
    }
    &--orange {
      background-color: $brand--orange-400;
      border: 0;
    }
    &--yellow {
      background-color: $brand--yellow-400;
      border: 0;
    }
    &--grey {
      background-color: $neutral--grey-400;
      border: 0;
    }
  }

  &--connector {
    grid-area: aconnector;
    height: 100%;
    width: 1px;
    margin-left: calc(($spacing-xs / 2) - 1px);
    background-color: $border-color;
  }
}
