@import 'src/stories/assets/styles/tokens';

.gaas-bottle-page {
  height: 100%;
  overflow-y: scroll;

  &--background {
    width: 100%;
    min-height: 100%;
    background-color: $contrast--white;
  }

  &--loading {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: $neutral--grey-100;

    & > svg {
      width: 100px;
    }
  }

  &--content {
    max-width: 532px;
    margin: 0 auto;
    padding: $spacing-m $spacing-m $spacing-xl $spacing-m;
  }
}
