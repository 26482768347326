@import './assets/styles/tokens';

.gaas-manometer-pairing {
  &--buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-m;
    margin-top: $spacing-m;
  }

  &--paired {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: $spacing-m;

    &--value {
      display: flex;
      align-items: center;
    }

    &--barcode {
      text-decoration: none;
      color: $brand--blue-400;
      &:hover {
        color: $brand--blue-500;
      }
    }
  }

  .pairingInfoIcon {
    height: 2.5rem;
    margin-right: 0.5rem;
    opacity: 0.5;
  }

  .pairingDropdownFillLevelIcon {
    height: 1.5rem;
    padding-left: 0.5rem;
    margin-bottom: 0.3rem;
  }

  .pairedContainer,
  .paired {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .loading {
    height: 2.5rem;
    width: 100%;
    border-radius: 10px;
    background: $gradient-animation-bg;
    animation: $gradient-animation;
  }

  .unpair {
    margin-right: 0.5rem;
  }
}
