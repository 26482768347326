@import './assets/styles/tokens';

.gaas-material-card {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  row-gap: $spacing-l;

  &--title {
    margin: 0;
    margin-bottom: $spacing-2xs;
    font-size: $display-2xs;
    font-weight: semibold;
    line-height: $line-height-display;
  }

  &--warnings {
    position: absolute;
    top: 0;
    right: 0;
  }

  &--description {
    display: block;
    font-weight: $light;
  }

  &--actions {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &--tabs {
      display: flex;
      flex-flow: row nowrap;
      column-gap: $spacing-xl;

      &--tab {
        height: $spacing-2xl;
        width: $spacing-2xl;
        display: flex;
        align-items: center;
        justify-content: center;

        &--active {
          background-color: $brand--blue-100;
        }
      }
    }
  }

  &--pin {
    display: flex;
    align-items: center;
    column-gap: $spacing-xs;

    &--dot {
      width: $spacing-xs;
      height: $spacing-xs;
      border-radius: 100%;
      border: 1px solid #000;

      &--red {
        border: 0;
        background-color: $brand--red-400;
      }
      &--green {
        border: 0;
        background-color: $brand--green-400;
      }
      &--orange {
        border: 0;
        background-color: $brand--orange-400;
      }
      &--yellow {
        border: 0;
        background-color: $brand--yellow-400;
      }
      &--grey {
        border: 0;
        background-color: $neutral--grey-400;
      }
    }
  }

  &--threshold {
    text-align: end;
  }

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
