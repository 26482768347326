@import '../../../stories/assets/styles/tokens';

.gaas-manometer-page {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $spacing-m;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-m;
  }

  @media (min-width: 1300px) {
    display: flex;
    flex-flow: row wrap;
  }

  &--empty {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    & > svg {
      width: 100px;
    }
  }
}
