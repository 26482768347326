@import './assets/styles/tokens';

.gaas-manometer-card-dummy {
  position: relative;
  width: 100%;
  max-width: 440px;
  background-color: $contrast--white;
  padding: $spacing-l;
  border-radius: $border-radius;
  height: fit-content;

  &--title {
    width: 100%;
    margin: 0;
    padding-bottom: $spacing-l;
    font-size: $display-xs;
    font-weight: $semibold;
    border-bottom: $border;
    color: $neutral--grey-400;
  }

  &--manometer {
    display: flex;
    justify-content: center;
    border-bottom: $border;
    margin-bottom: $spacing-m;

    svg {
      width: 100%;
      max-width: 380px;
    }
  }

  &--details {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-l;
  }

  &--detail {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-2xs;

    &--label,
    &--value {
      background-color: $neutral--grey-200;
    }

    &--label {
      height: $spacing-m;
      width: 25%;
    }
    &--value {
      height: $spacing-l;
      width: 50%;
    }
  }

  &--cta {
    border: 1px solid $neutral--grey-100;
    position: absolute;
    top: 45%;
    left: 20%;
    right: 20%;
    background-color: $contrast--white;
    padding: $spacing-l;
    border-radius: $border-radius;
    box-shadow: $shadow-m;
    text-align: center;
  }
}
