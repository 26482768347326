/**
  * @tokens Colors
  * @presenter Color
  */

$contrast--black: #000000;
$contrast--white: #ffffff;
$contrast--transparent: transparent;
$brand--red-700: #520b00;
$brand--red-600: #730f00;
$brand--red-500: #a51612;
$brand--red-400: #da0025;
$brand--red-300: #ffafa4;
$brand--red-200: #ffe4e0;
$brand--red-100: #fff3f0;
$brand--red-50: #fffbfa;
$brand--orange-700: #541e00;
$brand--orange-600: #7d3000;
$brand--orange-500: #d66900;
$brand--orange-400: #fe9415;
$brand--orange-300: #ffd499;
$brand--orange-200: #ffefe0;
$brand--orange-100: #fffaf3;
$brand--orange-50: #fffcfa;
$brand--yellow-700: #3d2800;
$brand--yellow-600: #7a5000;
$brand--yellow-500: #d69c00;
$brand--yellow-400: #ffcc00;
$brand--yellow-300: #ffee93;
$brand--yellow-200: #fff7c9;
$brand--yellow-100: #fffcf0;
$brand--yellow-50: #fffdf5;
$brand--green-700: #012c14;
$brand--green-600: #024d23;
$brand--green-500: #048b3f;
$brand--green-400: #05a54b;
$brand--green-300: #a4f4b6;
$brand--green-200: #e0ffe2;
$brand--green-100: #f5fff5;
$brand--green-50: #fbfffa;
$brand--darkgreen-700: #0a2f1f;
$brand--darkgreen-600: #0c3825;
$brand--darkgreen-500: #115236;
$brand--darkgreen-400: #197850;
$brand--darkgreen-300: #aaeed1;
$brand--darkgreen-200: #e0fff4;
$brand--darkgreen-100: #f5fffc;
$brand--darkgreen-50: #fafffd;
$brand--lightblue-700: #002738;
$brand--lightblue-600: #00425e;
$brand--lightblue-500: #0072a3;
$brand--lightblue-400: #009fe3;
$brand--lightblue-300: #99e1ff;
$brand--lightblue-200: #e0f9ff;
$brand--lightblue-100: #f5feff;
$brand--lightblue-50: #fafeff;
$brand--blue-700: #001a3c;
$brand--blue-600: #002c52;
$brand--blue-500: #005c99;
$brand--blue-400: #0078be;
$brand--blue-300: #99d4ff;
$brand--blue-200: #e0f3ff;
$brand--blue-100: #f3f9ff;
$brand--blue-50: #f8fcff;
$brand--darkblue-700: #00061a;
$brand--darkblue-600: #000929;
$brand--darkblue-500: #002247;
$brand--darkblue-400: #002f63;
$brand--darkblue-300: #b1c4e7;
$brand--darkblue-200: #e8edf8;
$brand--darkblue-100: #f7f9fd;
$brand--darkblue-50: #fbfcfe;
$brand--anthracite-400: #3c3732;
$neutral--grey-700: #282828;
$neutral--grey-600: #3c3c3c;
$neutral--grey-500: #646464;
$neutral--grey-400: #969696;
$neutral--grey-300: #c8c8c8;
$neutral--grey-200: #e6e6e6;
$neutral--grey-100: #f0f0f0;
$neutral--grey-50: #fafafa;
$overlay--white-50: rgba(#ffffff, 0.5);
$overlay--white-40: rgba(#ffffff, 0.4);
$overlay--white-30: rgba(#ffffff, 0.3);
$overlay--white-20: rgba(#ffffff, 0.2);
$overlay--white-10: rgba(#ffffff, 0.1);
$overlay--white-5: rgba(#ffffff, 0.05);
$overlay--black-50: rgba(#000000, 0.5);
$overlay--black-40: rgba(#000000, 0.4);
$overlay--black-30: rgba(#000000, 0.3);
$overlay--black-20: rgba(#000000, 0.2);
$overlay--black-10: rgba(#000000, 0.1);
$overlay--black-5: rgba(#000000, 0.05);
$overlay--red-50: rgba(#da0025, 0.5);
$overlay--red-40: rgba(#da0025, 0.4);
$overlay--red-30: rgba(#da0025, 0.3);
$overlay--red-20: rgba(#da0025, 0.2);
$overlay--red-10: rgba(#da0025, 0.1);
$overlay--red-5: rgba(#da0025, 0.05);
$overlay--darkblue-50: rgba(#002f63, 0.5);
$overlay--darkblue-40: rgba(#002f63, 0.4);
$overlay--darkblue-30: rgba(#002f63, 0.3);
$overlay--darkblue-20: rgba(#002f63, 0.2);
$overlay--darkblue-10: rgba(#002f63, 0.1);
$overlay--darkblue-5: rgba(#002f63, 0.05);
$error--700: #690032;
$error--600: #aa103e;
$error--500: #da0c56;
$error--400: #ec7699;
$error--300: #ffc0db;
$error--200: #ffe4f0;
$error--100: #fff3f7;
$error--50: #fff9fb;
$info--700: #001a3c;
$info--600: #002c52;
$info--500: #005c99;
$info--400: #3aa8e9;
$info--300: #99d4ff;
$info--200: #e0f3ff;
$info--100: #f3f9ff;
$info--50: #f8fcff;
$success--700: #012c14;
$success--600: #024d23;
$success--500: #048b3f;
$success--400: #5cd685;
$success--300: #a4f4b6;
$success--200: #e0ffe2;
$success--100: #f5fff6;
$success--50: #fbfffa;
$warning--700: #541e00;
$warning--600: #7d3000;
$warning--500: #bd5c00;
$warning--400: #ffbd00;
$warning--300: #ffee93;
$warning--200: #fff7c9;
$warning--100: #fffcf0;
$warning--50: #fffdf5;

/**
  * @tokens Font Families
  * @presenter FontFamily
  */

$web-font-family: 'WestfalenNewsSans', sans-serif;

/**
  * @tokens Font Sizes
  * @presenter FontSize
  */

$display-xl: 60px;
$display-l: 40px;
$display-m: 36px;
$display-s: 30px;
$display-xs: 24px;
$display-2xs: 20px;
$display-3xs: 18px;
$display-4xs: 16px;
$text-2xl: 20px;
$text-xl: 20px;
$text-l: 18px;
$text-m: 16px;
$text-s: 14px;
$text-xs: 12px;
$table-digits-l: 18px;
$table-digits-m: 16px;
$table-digits-s: 14px;
$table-digits-xs: 12px;
$link-2xl: 20px;
$link-xl: 20px;
$link-l: 18px;
$link-m: 16px;
$link-s: 14px;
$link-xs: 12px;

/**
  * @tokens Font Weights
  * @presenter FontWeight
  */

$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;

/**
  * @tokens Line Heights
  * @presenter LineHeight
  */

$line-height: 140%;
$line-height-display: 110%;

/**
  * @tokens Spacings
  * @presenter Spacing
  */

$spacing-3xs: 2px;
$spacing-2xs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-2xl: 40px;
$spacing-3xl: 48px;
$spacing-4xl: 64px;
$spacing-5xl: 80px;
$spacing-6xl: 96px;
$spacing-7xl: 160px;

/**
  * @tokens Border Radius
  * @presenter BorderRadius
  */

$border-radius: 12px;

/**
  * @tokens-end
  */

/** Shadows */

$shadow-xs: 0px 1px 2px 0px #0000000d;
$shadow-s: 0px 1px 3px 0px #0000001a;
$shadow-m: 0px 4px 8px -2px #0000001a;
$shadow-l: 0px 12px 16px -4px #00000014;
$shadow-xl: 0px 20px 24px -4px #00000014;
$shadow-2xl: 0px 24px 48px -12px #0000002e;
$shadow-3xl: 0px 32px 64px -12px #00000024;

/** Breakpoints */
@mixin break390 {
  @media only screen and (max-width: 390px) {
    @content;
  }
}
@mixin break768 {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
@mixin break1024 {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}
@mixin break1440 {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}
@mixin break1920 {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}

/** Animations */
$gradient-animation-bg: linear-gradient(
    70deg,
    $neutral--grey-100,
    $neutral--grey-100,
    $neutral--grey-50,
    $neutral--grey-100,
    $neutral--grey-100
  )
  left/200%;

@keyframes gradient-animation-keyframes {
  0% {
    background-position: 0;
  }
  50% {
    background-position: -100%;
  }
  100% {
    background-position: -200%;
  }
}

$gradient-animation: gradient-animation-keyframes 2s ease-in infinite;

@keyframes appear-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes disappear-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

$appear-animation: appear-keyframes 0.2s ease-out;
$disappear-animation: disappear-keyframes 0.2s ease-out;
$standard-transition: 0.2s all ease;

/** Helpers **/
$border-color: $neutral--grey-200;
$border: 1px solid $neutral--grey-200;
