@import './assets/styles/tokens';

.gaas-header-link,
.gaas-header-button {
  display: flex;
  align-items: center;
  column-gap: $spacing-xs;
  width: fit-content;
  text-decoration: none;
  height: 32px;
  color: $contrast--black;

  //animation
  background-image: linear-gradient($brand--red-400, $brand--red-400);
  background-size: 0% 2px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  transition: background-size 0.4s;

  &:hover {
    background-size: 100% 2px;
  }

  &--active {
    .gaas-header-link--icon * {
      fill: $brand--red-400;
    }
    .gaas-header-link--label {
      color: $brand--red-400;
    }
  }

  &--icon {
    width: $spacing-l;
    * {
      fill: #d9d9d9;
    }
  }

  &--badge {
    position: relative;
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
  }

  &--label {
    font-size: $text-m;
    font-weight: $semibold;
    padding-top: $spacing-2xs;
    padding: $spacing-2xs 0;
  }

  &--mobile {
    border-bottom: $border;
    width: 100%;
    height: 60px;

    .gaas-header-link--label,
    .gaas-header-button--label {
      font-size: $text-l;
    }
  }
}

.gaas-header-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}
