@import './assets/styles/tokens';

.gaas-gateway-status {
  display: flex;
  column-gap: $spacing-xs;

  &--icon {
    width: 48px;
    height: 48px;
    background-color: $neutral--grey-100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
