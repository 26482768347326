@import './assets/styles/tokens';

.gaas-devider {
  border: 0;
  border-bottom: $border;
  margin: $spacing-m 0;
  width: 100%;

  &--nomargin {
    margin: 0;
  }
}
