@import 'src/assets/styles/_colors';
@import 'src/assets/styles/breakpoints';

.background {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  background: linear-gradient(
    $secondary 80%,
    transparent 80%,
    transparent 100%
  );
  width: 100%;
  z-index: 999;

  @include sm {
    border-radius: 0 0 10px 10px;
    width: auto;
    min-width: 400px;
    max-width: 480px;
  }

  .container {
    position: relative;
    padding: 0.7rem 1.3rem 0rem 1.3rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    svg {
      fill: white;
    }

    p {
      text-align: center;
      color: white;
      margin: 0.5rem 0;
    }

    .close {
      background-color: $secondary;
      border-radius: 0 0 50px 50px;
    }
  }
  &.displayNone {
    display: none;
  }
}
