@import 'src/stories/assets/styles/tokens';

.gaas-group-page {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $spacing-m;
  column-gap: $spacing-m;

  @media (min-width: 820px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-m;
  }

  @include break1024 {
    display: flex;
    flex-flow: row wrap;
  }

  &--loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    & > svg {
      width: 80px;
    }
  }
}
