@import 'src/stories/assets/styles/tokens';

.gaas-bottles {
  display: flex;
  flex-flow: row nowrap;
  & > div {
    border: $border;
    display: inline-flex;
    height: 6rem;
  }

  &--inventory {
    margin-right: 0.5rem;
    width: calc(100% - 5.5rem);
  }

  &--column {
    width: 5rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    label {
      font-weight: $light;
    }
    & > div {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }

  &--icons {
    border-left: $border;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding: 0 1rem;
    align-items: center;
    width: calc(100% - 5rem);
    font-size: 1.4rem;
    font-weight: 600;
    padding-top: $spacing-xs;

    &--icon {
      display: inline-flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      row-gap: $spacing-2xs;
    }
  }
}
