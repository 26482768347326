@import-normalize;
@import '../../stories/assets/styles/tokens';
@import '../../stories/assets/styles/globalStyles';
@import '../../stories/assets/fonts/fontFaces.scss';

html {
  font-family: 'WestfalenNewsSans', sans-serif;
}

body {
  position: relative;
  display: flex;
  width: 100vw;
  margin: 0;
  background-color: $neutral--grey-100;

  @include break1024 {
    height: 100vh;
    overflow: hidden;
  }
}

#root {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}
