@import './assets/styles/tokens';

.gaas-notifications {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  opacity: 0;
  background-color: $overlay--black-50;

  @include break768 {
    justify-content: flex-end;
  }

  &--visible {
    opacity: 1;
    visibility: visible;
    animation: $appear-animation;
  }

  &--transitioning {
    animation: $disappear-animation;
  }

  &--container {
    background-color: $contrast--white;
    box-shadow: $shadow-l;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
    transition: margin-right 0.2s ease-out;

    @include break768 {
      width: 480px;
      margin-right: -480px;
      border-left: $border;
    }

    &--visible {
      margin-right: 0;
    }

    &--transitioning {
      @include break768 {
        margin-right: -480px;
      }
    }
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-l;
    border-bottom: $border;

    &--title {
      font-size: $display-xs;
      line-height: $line-height-display;
      font-weight: $light;
      margin: 0;
    }

    &--close {
      background-color: $contrast--transparent;
      border: 0;
      padding: 0;
      height: 24px;
      cursor: pointer;
    }
  }

  &--content {
    height: 100%;
    padding: $spacing-l;
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-m;
    overflow-y: scroll;
  }

  &--readAll {
    align-self: flex-end;
    width: 100%;
    padding: $spacing-l;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: $border;
  }
}
