@import './assets/styles/tokens';

.gaas-plansChip {
  position: absolute;
  height: 28px;
  width: fit-content;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  column-gap: $spacing-2xs;
  background-color: $brand--lightblue-400;
  border-radius: 40px;
  white-space: nowrap;
  box-shadow: $shadow-s;
  overflow: hidden;

  transition: all 0.2s;

  &--icon {
    height: 20px !important;
    width: 20px !important;
    * {
      fill: $contrast--white;
    }
  }

  &--label {
    max-width: 24px;
    margin-left: -28px;
    font-size: $text-m;
    text-align: end;
    color: $contrast--white;
    opacity: 0;
    transition: all 0.2s;
  }

  &--hovered {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    padding: 2px 10px;

    .gaas-plansChip--label {
      max-width: 200px;
      width: auto;
      margin-left: 0px;
      opacity: 1;
    }
  }
}
