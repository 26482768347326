@import 'src/assets/styles/_colors';

.withButton {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
}

.searchInput {
  width: 100%;
  input::placeholder {
    font-weight: 600;
  }
  label {
    color: rgba($primary, 0.5);
    font-weight: 600;
  }
  button {
    width: 42px;
    height: 42px;
    margin-right: -8px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  margin-bottom: 1.5rem;
}

.nothing {
  border-top: $border;
  height: 15rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  .img {
    width: 10rem;
    height: 10rem;
  }
}

.fakeItems {
  .fakeItem {
    margin-bottom: 1rem;
    padding: 1.3rem 1rem;
    height: 20px;
    border-radius: $border-radius;
    background: $gradient-animation-bg;
    animation: $gradient-animation;
  }
}
