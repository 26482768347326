@import './assets/styles/tokens';

.gaas-input {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  input {
    height: $spacing-2xl;
    margin: 0;
    margin-top: $spacing-2xs;
    padding-left: $spacing-xs;
    padding-right: $spacing-xs;
    border: 1px solid $neutral--grey-400;
    border-radius: 0;

    background-color: transparent;
    font-size: $text-m;
    font-weight: $light;
    caret-color: $brand--lightblue-400;
    text-overflow: ellipsis;

    &::placeholder {
      font-size: $text-m;
      font-weight: $light;
      color: $neutral--grey-400;
    }
    &:hover {
      border-color: $contrast--black;
    }
    // &:focus {
    //   padding-right: 36px;

    //   + .gaas-input--icon--cancel {
    //     display: block;
    //   }
    // }
    transition: $standard-transition;
  }

  &--disabled {
    span[class*='--label'] {
      color: $neutral--grey-300 !important;
    }
    input {
      color: $neutral--grey-300;
      border-color: $neutral--grey-300;
      &:hover {
        border-color: $neutral--grey-300;
      }
    }
  }

  &--error {
    input {
      border-color: $error--400;
      padding-right: 40px;
    }
  }

  &--icon {
    position: absolute;
    right: $spacing-xs;
    top: 32px;

    // &--cancel {
    //   display: none;
    //   cursor: pointer;

    //   * {
    //     fill: $neutral--grey-400;
    //   }
    // }
    &--error {
      * {
        fill: $error--500;
      }
    }
  }

  &--hint {
    color: $error--500;
    margin: 0;
    margin-top: $spacing-2xs;
  }

  &--unit {
    position: absolute;
    right: $spacing-xs;
    top: 34px;
    line-height: 24px;
    font-weight: $semibold;
    color: $neutral--grey-400;
    text-align: end;
  }

  &--s {
    input {
      height: $spacing-l;
    }
    .gaas-input--icon,
    .gaas-input--unit {
      top: 26px;
    }
  }

  &--l {
    input {
      height: $spacing-3xl;
    }
    .gaas-input--icon,
    .gaas-input--unit {
      top: 38px;
    }
  }

  &--withUnit {
    input {
      padding-right: 36px;

      &:focus {
        padding-right: 68px;
        ~ .gaas-input--unit {
          right: $spacing-2xl;
        }
      }
    }
  }
}
