@import './assets/styles/tokens';

.gaas-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 999;
  left: 70%;
  top: -1.5rem;
  background-color: $contrast--black;
  box-shadow: $shadow-m;
  z-index: 100;
  padding: 0.3em 0.8em;
  font-size: 14px;
  overflow: hidden;

  &--text {
    margin: 0;
    width: 100%;
    white-space: normal;
    color: $contrast--white;
  }

  &--trigger {
    position: relative;
    width: fit-content;
    font-weight: 600;
    cursor: pointer;

    &:hover > div {
      visibility: visible;
    }
  }
}
