@import './assets/styles/tokens';

.gaas-group-material {
  height: $spacing-3xl;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border: $border;

  &--material {
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: $spacing-s;
    border-right: $border;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--values {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $spacing-xs;
    border-right: $border;

    &--value {
      display: flex;
      column-gap: $spacing-2xs;

      & > span {
        width: $spacing-l;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &--buttons {
    display: flex;
    flex-flow: row nowrap;
    column-gap: $spacing-m;
    padding: $spacing-s;
  }
}
