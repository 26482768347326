@import './assets/styles/tokens';

.gaas-infoBox {
  display: flex;
  column-gap: $spacing-s;
  padding: $spacing-m;
  background-color: $info--100;
  color: $info--700;
  font-weight: $light;

  &--icon {
    svg * {
      fill: $info--500;
    }
  }
}
