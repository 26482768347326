@import './assets/styles/tokens';

.gaas-pin-modal {
  &--colors {
    display: flex;
    justify-content: space-between;
  }

  &--buttons {
    display: flex;
    justify-content: flex-end;

    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-m;
    }
  }
}
