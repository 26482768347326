$spinner_background: #dbdbdb;

$message_color_success: green;
$message_color_error: red;
$message_color_warning: orange;
$message_color_info: blue;

.gaas-om-details-container {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;

  overflow-y: auto;
  max-height: 300px;
  max-width: 400px;
}

.gaas-om-header {
  padding: 5px;
  margin: 0px;
}

.gaas-om-message {
  padding: 3px;
  margin: 0px;
}
