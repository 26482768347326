@import './assets/styles/tokens';

.gaas-badge {
  background-color: $brand--red-400;
  border-radius: 100%;
  width: $spacing-m;
  height: $spacing-m;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: -25%;

  &--count {
    color: $contrast--white;
    font-size: $text-xs;
    font-weight: $semibold;
  }
}
