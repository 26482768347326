@import './assets/styles/tokens';

.gaas-barcode-scanner-modal {
  &--content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand--blue-100;

    @media only screen and (max-width: 1024px) {
      height: 100%;
    }
  }
}
