@import '../../../stories/assets/styles/tokens';

.gaas-inventory-page {
  &--title {
    font-size: $display-s;
    font-weight: $light;
    line-height: $line-height-display;
    margin-top: 0;
  }

  &--loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    & > svg {
      width: 100px;
    }
  }
}
