@import './assets/styles/tokens';

.gaas-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: $spacing-2xs;
  padding: 0 12px;
  border: 0;
  font-weight: $semibold;
  color: $contrast--white;
  cursor: pointer;

  //animation
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  transition: all 0.4s;

  &:hover {
    &:not(:disabled) {
      background-size: 100% 100%;
    }

    .gaas-button--icon * {
      fill: $contrast--white;
      transition: all 0.4s;
    }
  }

  &:disabled {
    cursor: default;
  }

  &--primary {
    background-color: $brand--darkblue-400;
    background-image: linear-gradient(
      $brand--darkblue-500,
      $brand--darkblue-500
    );
    color: $contrast--white;

    &:active {
      background-color: $brand--blue-600;
    }

    &:disabled {
      background-color: $brand--darkblue-300;
    }

    .gaas-button--icon * {
      fill: $contrast--white;
    }
  }

  &--outlined {
    border: 1px solid $contrast--black;
    background-color: $contrast--transparent;
    background-image: linear-gradient($contrast--black, $contrast--black);
    color: $contrast--black;

    &:hover {
      color: $contrast--white;
    }

    &:active {
      background-color: $contrast--black;
    }

    &:disabled {
      color: $neutral--grey-300;
      border-color: $neutral--grey-300;

      .gaas-button--icon * {
        fill: $neutral--grey-300;
        transition: all 0.4s;
      }
    }
  }

  &--success {
    background-color: $brand--green-400;
    background-image: linear-gradient($brand--green-500, $brand--green-500);
    color: $contrast--white;

    &:active {
      background-color: $brand--green-600;
    }

    &:disabled {
      background-color: $brand--green-300;
    }

    .gaas-button--icon * {
      fill: $contrast--white;
    }
  }

  &--s {
    font-size: $text-s;
    height: 32px;

    .gaas-button--icon {
      width: 16px;
    }
  }

  &--m {
    font-size: $text-m;
    height: 40px;

    .gaas-button--icon {
      width: 20px;
    }
  }

  &--l {
    font-size: $text-l;
    height: 48px;

    .gaas-button--icon {
      width: 20px;
    }
  }

  &--fullwidth {
    width: 100%;
    padding: 0;
  }

  &--permission {
    position: relative;
    background-color: $brand--darkblue-300;
    cursor: pointer !important;
    .gaas-button--icon * {
      fill: $contrast--white;
    }

    &:hover {
      background-size: 0 100% !important;
      box-shadow: 0px 0px 8px $brand--blue-300;
      outline: 1px solid $brand--blue-300;
    }
    &:active {
      background-color: $brand--lightblue-300;
    }
    &:focus {
      outline: $brand--lightblue-400;
    }
  }
}
