@import './tokens';

* {
  box-sizing: border-box;

  &:focus {
    outline: 2px solid $brand--blue-400;
  }
}

h1 {
  font-size: $display-m;
  font-weight: $light;
  margin: 0;

  font-family: $web-font-family;
  line-height: $line-height;
  color: $contrast--black;
}

h2 {
  font-size: $display-s;
  font-weight: $light;
  margin: 0;

  font-family: $web-font-family;
  line-height: $line-height;
  color: $contrast--black;
}

span {
  font-family: $web-font-family;
  line-height: $line-height;
  color: $contrast--black;
}

a {
  text-decoration: none;
  color: $brand--blue-400;
  cursor: pointer;
  &:hover {
    color: $brand--blue-500;
  }
  &:disabled {
    cursor: initial;
  }
}
