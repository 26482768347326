@import 'src/assets/styles/_colors';

.container {
  display: grid;
  background-color: white;
  box-shadow: $box-shadow;
  margin-bottom: 1rem;
  padding: 1.3rem 1rem;
  border-radius: $border-radius;
  border: 1px solid transparent;
  transition: $transition;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba($primary, 0.3);
    border-color: rgba($primary, 0.3);
  }
}

.entries {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  .overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    width: 15%;
    background: linear-gradient(90deg, transparent, white, white);
  }
}
