@import './assets/styles/tokens';

.gaas-text-button {
  display: flex;
  align-items: center;
  column-gap: $spacing-2xs;
  padding: 0;
  border: 0;
  font-size: $text-m;
  font-weight: $semibold;
  color: $brand--blue-400;
  background-color: $contrast--transparent;
  cursor: pointer;

  transition: all 0.2s ease;

  &--inline {
    display: inline-flex;
  }

  &--icon * {
    fill: $brand--blue-400;
    transition: all 0.1s ease;
  }

  &--badge {
    position: relative;
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
  }

  &:disabled {
    color: $brand--blue-300;
    pointer-events: none;

    .gaas-text-button--icon * {
      fill: $brand--blue-300;
    }
  }

  &:hover {
    color: $brand--blue-500;

    .gaas-text-button--icon * {
      fill: $brand--blue-500;
    }
  }

  &:active,
  &--active {
    color: $brand--blue-600;

    .gaas-text-button--icon * {
      fill: $brand--blue-600;
    }
  }

  &--s {
    font-size: $text-s;
    .gaas-text-button--icon {
      width: 20px;
    }
  }

  &--m {
    font-size: $text-m;
    .gaas-text-button--icon {
      width: 20px;
    }
  }

  &--l {
    font-size: $text-l;
    .gaas-text-button--icon {
      width: 24px;
    }
  }

  &--permission {
    position: relative;
    color: $brand--darkblue-300;

    .gaas-text-button--icon * {
      fill: $brand--darkblue-300;
    }
    cursor: pointer !important;

    &:hover {
      background-size: 0 100% !important;
      color: $brand--lightblue-300;

      .gaas-text-button--icon * {
        fill: $brand--lightblue-300;
      }
    }
    &:active {
      color: $brand--lightblue-400;

      .gaas-text-button--icon * {
        fill: $brand--lightblue-400;
      }
    }
    &:focus {
      outline: $brand--lightblue-400;
    }
  }
}
