@import './assets/styles/tokens';

.gaas-sidebar {
  padding: $spacing-xl $spacing-m $spacing-l;
  background-color: $contrast--white;
  border-bottom: $border;
  max-width: 100vw;

  @include break768 {
    padding: $spacing-l;
  }

  @include break1024 {
    min-width: 320px;
    max-width: 320px;
    border-right: $border;
    padding: $spacing-xl $spacing-l;
  }

  @include break1440 {
    min-width: 360px;
    max-width: 360px;
    padding: $spacing-xl $spacing-l $spacing-xl $spacing-xl;
  }

  &--title {
    font-size: $display-m;
    font-weight: $light;
    margin: 0;
    margin-bottom: $spacing-3xl;
  }
}
