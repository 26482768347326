@import './assets/styles/tokens';

.gaas-group-card-dummy {
  position: relative;
  background-color: $contrast--white;
  padding: $spacing-l;
  border-radius: $border-radius;
  height: fit-content;
  width: 100%;
  max-width: 450px;

  &--title {
    width: 100%;
    margin: 0;
    padding-bottom: $spacing-l;
    font-size: $display-xs;
    font-weight: $semibold;
    border-bottom: $border;
    color: $neutral--grey-400;
  }

  &--summary {
    margin-top: $spacing-l;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-xs;

    &--box {
      height: $spacing-6xl;
      border: $border;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      &--value {
        height: $spacing-l;
        width: 5rem;
        background-color: $neutral--grey-200;
      }
    }
  }

  &--list {
    margin-top: $spacing-l;
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-s;
    &--item {
      height: $spacing-3xl;
      width: 100%;
      background-color: $neutral--grey-200;
    }
  }

  &--cta {
    position: absolute;
    top: 42%;
    left: 20%;
    right: 20%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: $spacing-l;
    text-align: center;
    background-color: $contrast--white;
    border: 1px solid $neutral--grey-100;
    border-radius: $border-radius;
    box-shadow: $shadow-m;
  }
}
