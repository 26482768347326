@import 'src/assets/styles/_colors';

.container {
  display: grid;
  padding: 0 1rem 0.5rem;
}

.button {
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: start;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8em;
  &.active {
    font-weight: 600;
  }
  &:hover {
    font-weight: 600;
  }
}
