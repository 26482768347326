@import '../stories/assets/styles/tokens';

.gaas-salesforce-auth {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $contrast--white;

  &--container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  &--title {
    margin: $spacing-xl 0 $spacing-2xl 0;
  }

  &--text {
    margin-bottom: 0;
  }
}
