@import 'src/stories/assets/styles/tokens';

.gaas-gas {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $spacing-m;

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-m;
  }

  @include break1024 {
    display: flex;
    flex-flow: row wrap;
  }

  @media (min-width: 1150px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-m;
  }

  @include break1440 {
    display: flex;
    flex-flow: row wrap;
  }
}
