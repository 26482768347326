@import './assets/styles/tokens';

.gaas-card {
  background-color: $contrast--white;
  box-shadow: $shadow-m;
  padding: $spacing-l;
  border-radius: $border-radius;
  height: fit-content;

  @keyframes bop {
    from {
      background-color: $brand--blue-200;
    }
    to {
      background-color: white;
    }
  }

  &--focused {
    animation: bop 2s ease-out;
  }
}
