@import './assets/styles/tokens';

.gaas-checkbox {
  display: flex;
  align-items: flex-start;
  column-gap: $spacing-2xs;

  &--label {
    display: flex;
    flex-flow: column nowrap;
    font-weight: $light;
  }
}
