@import 'src/stories/assets/styles/tokens';

.gaas-firewall-check {
  &--text {
    & > p:first-of-type {
      margin-top: 0;
    }
  }
  &--code {
    background-color: $contrast--black;
    padding: 0.5rem 1rem 0.7rem 1rem;
    border-radius: 12px;
    span {
      color: $contrast--white;
      font-family: monospace;
    }
  }
}
