@import 'src/assets/styles/breakpoints';
@import 'src/stories/assets/styles/tokens';

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  height: 100%;

  @include break1024 {
    flex-flow: row nowrap;
    height: 100%;
    overflow: hidden;
  }
}

.content {
  height: 100%;
  width: 100%;
  padding: $spacing-l $spacing-m;
  overflow: hidden;

  @include break768 {
    padding: $spacing-l;
  }
  @include break1024 {
    overflow-y: scroll;
    padding: $spacing-xl;
  }
}

.emptyContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 120px;
  }
}
