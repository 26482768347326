@import '../../../stories/assets/styles/tokens';

.historyButtons {
  width: 100%;
  display: flex;
  column-gap: $spacing-m;
  a {
    text-decoration: none;
  }
}

.chartWrap {
  margin: 1rem 0;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
