@import 'src/assets/styles/_colors';
@import 'src/assets/styles/breakpoints';

.container {
  background-color: rgba($primary, 0.6);
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 0.5rem;

  svg {
    fill: white;
  }

  p {
    color: white;
    margin: 0;
    margin-top: 0.5rem;
    text-align: center;
    width: 90%;
    margin-bottom: 0.5rem;
  }

  .close {
    @include sm {
      position: absolute;
      right: 1rem;
    }
  }

  &.displayNone {
    display: none;
  }
}
