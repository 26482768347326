@import './assets/styles/tokens';

.gaas-manometer-card {
  &--header {
    display: flex;
    align-items: center;
    column-gap: $spacing-l;
    height: $spacing-5xl;
    margin-top: -$spacing-l;
    border-bottom: $border;

    &--title {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
      font-size: $display-xs;
      line-height: $line-height-display;
      font-weight: $semibold;
    }
  }

  &--manometer {
    padding: $spacing-l 0 $spacing-m 0;

    &--values {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      margin-bottom: $spacing-m;

      span {
        font-size: $display-xs;
        font-weight: $light;
        line-height: $line-height-display;
      }

      &--withIcon {
        display: flex;
        align-items: center;
        column-gap: $spacing-xs;

        &--icon {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  &--details {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-l;
  }
}
