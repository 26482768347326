@import './assets/styles/tokens';

.gaas-label-and-text {
  display: flex;
  flex-flow: column nowrap;

  &--label {
    font-size: $text-m;
    line-height: $text-xl;
    font-weight: $light;
    display: flex;
  }

  &--content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    &--additional {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      column-gap: 8px;
    }
  }

  &--text {
    font-size: $text-l;
    font-weight: $semibold;

    &--success {
      color: $success--500;
    }

    &--warning {
      color: $warning--500;
    }

    &--error {
      color: $error--500;
    }
  }
}
