@import './assets/styles/tokens';

.gaas-material-list {
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;

  &--labels {
    position: sticky;
    top: -1px;
    z-index: 1;
    display: grid;
    column-gap: $spacing-s;
    background-color: $contrast--white;

    &--label {
      font-size: $text-s;
      font-weight: $semibold;
      text-align: end;
      &:first-of-type {
        text-align: start;
      }
    }
  }

  &-item {
    display: grid;
    column-gap: $spacing-s;
    align-items: center;
    height: $spacing-xl;

    font-size: $text-s;
    white-space: nowrap;
  }
}
