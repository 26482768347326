@import './assets/styles/tokens';

.gaas-header {
  position: sticky;
  z-index: 999;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: $spacing-s $spacing-m;

  @include break768 {
    padding: $spacing-l;
  }
  @include break1440 {
    padding: $spacing-l $spacing-xl;
  }
  @include break1920 {
    padding: $spacing-l $spacing-2xl;
  }

  border-bottom: $border;
  background-color: $contrast--white;

  &--logo {
    height: $spacing-2xl;
    width: $spacing-2xl;

    @include break768 {
      height: $spacing-3xl;
      width: $spacing-3xl;
    }

    @include break1024 {
      width: auto;
    }
  }

  &--menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: $spacing-l;
  }

  &--drawer {
    position: sticky;
    z-index: 998;
    top: 65px;
    right: 0;
    left: 0;
    padding: $spacing-l $spacing-m;
    background-color: $contrast--white;
    border-bottom: $border;

    //animation
    animation: appear 0.3s ease-in;

    @keyframes appear {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}
